.pagination {
    p {
        padding: .5em .75em .25em;
        background: $grayLight;
        display: inline-block;
        &+nav {
            margin-left: 1em;
        }
    }
    nav {
        display: inline-block;
    }
    ul {
        display: inline-block;
        li {
            padding: .5em .75em .25em;
            background: $grayLight;
            margin-right: .5em;
            margin-bottom: .5em;
            &:last-child {
                margin-right: 0em;
            }
            &.active {
                background: $grayDarker;
                color: #fff;
            }
        }
        &.paging-selector li {
            cursor: pointer;
        }
    }
    .paging-items-by-page-selector {
        margin-left: .5em;
        display: inline-block;
        .ui-select-container {
            &.dropdown.open {
                min-height: 18px;
                min-width: 38px;
            }
            .ui-select-match {
                span a {
                    display: none;
                }
                .form-control {
                    width: auto;
                    padding: .5em 1.25em .15em .5em;
                    height: auto;
                    font-size: 1em;
                    .caret {
                        right: .35em;
                    }
                }
            }
            .ui-select-search {
                display: none;
            }
            .ui-select-choices {
                width: auto;
                &.dropdown-menu {
                    min-width: 0;
                    font-size: 1em;
                    padding: 0;
                    top: 0;
                    li {
                        padding: 0;
                        margin-left: 0;
                        background: #fff;
                        .ui-select-choices-row {
                            padding: .5em .75em .25em .65em;
                            text-align: center;
                            a {
                                text-decoration: none;
                                padding-left: 0;
                                padding-right: 0;
                            }
                            &.active {
                                background-color: #428bca;
                            }
                        }
                    }
                }
            }
        }
    }
}