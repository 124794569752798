.ngb-dp-weekday {
    color: $greenFocus !important;
}

.ngb-dp-arrow {
    button.btn-link {
        display: flex;
        align-self: center;
        margin-top: 0 !important;
    }
}