    app-forms-bidirectional,
    app-manual-publication {
        &:not(app-forms-bidirectional) {
            .d-flex.border-bottom,
            .d-block.border-bottom {
                @include title_with_border();
                margin-right: 15px;
                margin-left: 15px;
            }
            h2 {
                padding-left: 0.625em;
            }
            fieldset legend {
                font-size: em(16, $font-size-base);
                font-weight: bold;
            }
            .form-check {
                border: 1px solid white;
                padding: .5em 1.5em 0em
            }
            .form-check.selected {
                border: 1px solid $grayLine;
                border-radius: .25rem;
            }
            .input-group input {
                border-color: $input-border-color !important;
            }
            .fas,
            .far {
                margin-right: 0.667em;
            }
        }
        &:not(app-manual-publication) {
            padding: .533em 0em 0.733em;
            h3 {
                margin-left: 15px
            }
            @include mq('xs') {
                &.border {
                    border-width: 0px !important;
                }
            }
            @include mq('sm') {
                padding: 1.8em 2.867em;
                border-radius: 4px;
                border-width: 2px !important;
            }
            margin-right: .75em;
            @include mq('xl') {
                margin-right: 0;
            }
            label .invalid-feedback span {
                margin-left: .5em;
            }
        }
        p+h2 {
            margin-top: 0em;
        }
        h2 {
            margin-bottom: 1em;
        }
        h3 {
            margin: 0em 0 1em 0em;
        }
        .fa-info-circle {
            color: $blue;
        }
        form {
            h3 {
                margin-left: 15px;
                margin-bottom: 0em;
            }
            p.required {
                margin-top: 0;
                margin-bottom: 0;
                margin-right: -15px;
            }
            @include mq ('sm') {
                margin-right: -15px;
                margin-left: -15px;
            }
            label {
                color: $grayDarker;
                font-family: OpenSansRegular;
                margin-bottom: 0;
            }
            textarea {
                padding: 1.133em 1.533em;
            }
            .btn-primary {
                font-size: 0.933em;
            }
            .btn-link {
                color: $blackLinkFont;
                font-family: 'OpenSansBold';
                margin-left: 1.400em;
            }
            .alert-danger[role=alert] {
                margin-left: 0px;
                width: auto;
                border: 1px solid $red;
                background-color: #fff;
                h4 {
                    margin-bottom: 0em;
                    display: inline-block;
                }
                &>h4+div {
                    padding: 0em !important;
                }
                ul,
                p {
                    margin-bottom: 0em;
                    margin-top: 0em;
                    font-size: 1em;
                    color: $blackLinkFont;
                }
            }
        }
        p {
            margin: 1.417em 0.833em 0.7915em .833em;
            @include mq("sm") {
                margin-bottom: 3.5em;
                margin-top: 2.8em;
            }
        }
        small {
            font-size: 90%;
            line-height: 20px;
            a {
                color: $blackFont;
                display: block;
                text-decoration: underline;
            }
        }
    }