#errorPage,
#notFoundPage {
    h2 {
        padding: 1.5em 0.833em 1.5em .5em;
        padding-top: 0.7085em;
        font-family: OpenSansBold;
        width: 100%;
        margin-bottom: 0em;
        @include mq ('sm') {
            margin-top: 0em;
        }
    }
    p {
        margin: 1.417em 0.833em 0.7915em 0em;
        font-size: 1.333em;
        @include mq("sm") {
            margin-bottom: 0em;
            margin-top: 0em;
        }
        display: block;
    }
    .card {
        border-color: $grayMiddle !important;
        margin-top: 2.8em;
        @include mq("sm") {
            margin-top: 6em;
        }
        border-radius: 1em;
        .card-header {
            padding: 0 0.833em;
            background: $gradientDefault;
            background: -moz-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            background: -webkit-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            background: linear-gradient(to right, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#{$gradientStart}', endColorstr='#{$gradientStop}', GradientType=1)";
            &:first-child {
                border-radius: calc(1em - 1px) calc(1em - 1px) 0 0;
            }
        }
        .card-footer {
            background: none;
            border: none;
            padding-bottom: 2rem;
            padding-left: 1.25rem;
        }
    }
    button {
        &.btn {
            height: calc(#{$input-height-inner} + 2px) !important;
            font-size: 1.333em;
            margin-top: -.6em;
        }
        &.btn-link {
            text-decoration: underline;
        }
    }
}