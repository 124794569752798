.managementPage,
.managementPartition,
.managementPartitionUsers {
    caption {
        caption-side: top;
        font-size: 20px;
        color: $blackLinkFont;
    }

    &:not(.managementPartitionUsers):not(.managementPage) {
        @include mq("xs") {
            form {
                overflow-y: scroll;
                height: 75%;
            }
        }
    }

    padding-left: 15px;
    padding-right: 15px;

    .d-flex.border-bottom,
    .d-block.border-bottom {
        @include title_with_border();
    }

    .hasAdminRightPartitition {
        p {
            width: 100%;
        }
    }

    .table {
        background-color: $grayLighter;

        thead th {
            border-top: 0px;
            border-bottom: 1px solid #dee2e6;
            font-family: OpenSansBold;
            font-size: 1em;
            background: transparent !important;
            padding: 0.75rem !important;
        }

        tbody {
            background-color: white;

            @include mq("xs") {
                tr {
                    border-bottom: 1px solid #24272c !important;

                    &:last-child {
                        margin-bottom: 0em !important;
                        border-bottom: 0px solid $blackLinkFont !important;
                    }

                    td {
                        & {
                            line-height: em(18, $font-size-base);
                        }

                        &:first-child {
                            margin-bottom: .5em;
                        }

                        &.right {

                            &-publish,
                            &-admin,
                            &-consult {
                                font-size: em(13, $font-size-base);
                                line-height: em(15, $font-size-base);
                            }
                        }

                        border: 0;
                        padding: 0em;

                        &.p-xs-reset {
                            padding: .75rem !important;
                            word-break: break-all;
                        }

                        &.nbApplicationUsers,
                        &.nbHumanUsers {
                            word-break: break-word;
                            width: 50%;

                            &:not(.nbHumanUsers) {
                                text-align: right;
                                padding-right: .75rem;
                            }

                            &:not(.nbApplicationUsers) {
                                text-align: left;
                                padding-left: .75rem;
                            }
                        }

                        &.border-right,
                        &.border-left {
                            border-right-width: 1px !important;
                            border-right-color: $blackLinkFont !important;
                            border-left-width: 1px !important;
                            border-left-color: $blackLinkFont !important;
                        }

                        &.right-admin+.right-consult {
                            padding-left: 0.5rem !important;
                        }

                        &.actions {
                            word-break: normal;
                            flex-wrap: wrap;
                            @include clearfix;
                            padding-bottom: 0.5em;

                            button,
                            a.btn {
                                width: 48%;
                                font-size: em(12, $font-size-base);
                                line-height: em(15, $font-size-base);

                                i {
                                    font-size: em(15, 12);
                                }

                                &.btn-secondary {
                                    i {
                                        color: $greenFont;
                                    }
                                }

                                &:nth-child(-n+3) {
                                    margin-left: 0em;
                                    margin-top: 1em;
                                }
                            }
                        }
                    }
                }
            }

            td {
                padding: 0.75rem;

                @include mq("xs") {}

                font-family: OpenSansRegular;

                a {
                    &:first-child {
                        margin-left: 0;
                    }

                    margin-left:1em;

                    i {
                        color: $blackLinkFont;
                    }
                }
            }
        }
    }

    @include mq('xs') {
        .modal-backdrop {
            opacity: 0;
        }

        .modal {
            position: relative;
        }
    }

    .pacman {
        margin-top: 2em;
    }
}

@each $color,
$value in $icon-browser-colors {

    .btn-#{$color},
    .link-#{$color} {
        .fa-browser {
            &:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='15'%3E%3Cpath d='M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM128 148c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm320 0c0 6.6-5.4 12-12 12H188c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h248c6.6 0 12 5.4 12 12v40z' fill='#{svg-encode('#{$value}')}' /%3E%3C/svg%3E%0A");
            }
        }
    }
}

.fa-browser {
    display: inline-block;
    vertical-align: middle;
}

.policyContainer {
    ol {
        @include mq ('xs') {
            padding-left: 0.5em;
        }
    }

    .fixedSizeBox {
        height: 400px;
        overflow-y: scroll;
        overflow-x: auto;

        a {
            text-decoration: underline;
        }

        p {
            margin-bottom: .5rem;
        }

        h4 {
            margin: 0;
        }

        ol {
            counter-reset: item;
            line-height: 20px;

            li {
                display: block;

                &::before {
                    content: counters(item, ".") ") ";
                    counter-increment: item;
                    font-weight: bold;
                    font-family: OpenSansSemibold;
                    font-size: 16px;
                }

                & li::before,
                h5 {
                    font-size: 0.9333333333em;
                }

                &>*:first-child {
                    display: inline-block;
                }
            }


        }
    }


}