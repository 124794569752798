$card-spacer-old-x: calc(#{$card-spacer-x} + 0.25rem);

.modal {
    h3 {
        padding: 0em 0.833em 0em 0em;
        font-family: 'OpenSansBold';
        font-size: 1.200em;
    }

    button {
        &.btn {
            height: calc(#{$input-height-inner} + 2px);
        }

        &.btn-link {
            color: $blackLinkFont;
            font-family: 'OpenSansBold';
            margin-left: 1.400em;
            text-decoration: none;
        }
    }

    form.form-inline {
        align-items: start;
    }

    form .form-group {
        display: block;
    }

    .alert {
        p {
            margin-bottom: 0em;
        }

        &-info,
        &-warning {
            @include mq('xs') {
                &>div {
                    display: block;
                }
            }

            color: $blackFont;

            &:not(.alert-info),
            &:not(.alert-warning) {
                @include mq('sm') {
                    background: transparent;

                    h2 {
                        background-color: $yellow;
                    }
                }
            }

            @at-root .modal app-email-update .alert-warning {
                @include mq('sm') {
                    background-color: $bg-yellow !important;
                    color: var(--bs-alert-color);
                    line-height: 1.75em;
        font-size: 0.933em;
                }
            }
        }
    }

    .modal {
        &-content {
            border: 0 !important;
            border-top-right-radius: 0.3rem;
        }

        &-body,
        &-footer {
            p {
                font-size: 1.067em;
                font-family: OpenSansRegular;
                line-height: 1.600em;

                strong {
                    font-family: OpenSansBold;
                }
                &.form-text{
                    font-size:0.875em
                }
            }

        }
    }
}

.deletePartitionModal,
.movePartitionModal {
    .modal {
        &-content {
            padding-top: 1.4em;
            padding-left: 2.267em;
            padding-right: 2.267em;

        }

        &-body,
        &-footer {
            .card-header {
                &.alert-danger {
                    border-width: 1px;
                    border-style: solid;
                    border-bottom-right-radius: calc(0.25rem - 1px);
                    border-bottom-left-radius: calc(0.25rem - 1px);

                    p {
                        margin-bottom: 0em;
                    }
                }

            }

            .dropdownPartitionMove {
                &>.btn-secondary.dropdown-toggle {
                    height: auto;
                    word-break: break-all;
                    white-space: normal;
                    min-height: calc(#{$input-height-inner} + 2px);

                    &,
                    &:active {
                        font-family: OpenSansBold;
                        color: $blackFont;
                    }
                }
            }
        }

    }

    h3 {
        font-size: 1.200em;
    }
}

.optin {
    h3 {
        padding: 0em 0.833em 0em 0em;
        font-size: 2.133em;

    }

    .optinflow {
        .modal {

            &-header {
                .languageLink {
                    text-transform: uppercase;
                    padding: 5px;
                    cursor: pointer;
                }

                .logo {
                    border-bottom-left-radius: 37px;
                    border-top-right-radius: 0.3rem;
                    padding: 1.333em;
                    background: $gradientDefault;
                    background: -moz-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                    background: -webkit-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                    background: linear-gradient(to right, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                    filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#{$gradientStart}', endColorstr='#{$gradientStop}', GradientType=1)";
                }

                h3 {
                    @include mq('xs') {
                        margin-top: 0em !important;
                        margin-left: 1em !important;
                    }

                    margin-left: 2.197em !important;
                }
            }

            &-body,
            &-footer {
                $marginLeft: 4em;
                $marginLeftMobile: 1em;

                &:not(.modal-footer) {
                    margin-left: $marginLeft;

                    @include mq('xs') {
                        margin-left: $marginLeftMobile !important;

                        .form-style {
                            margin-left: calc(#{$marginLeftMobile * -1} - 1.05rem) !important;
                            margin-right: $marginLeftMobile * -1.25 !important;
                            padding: 0.467em 0 0em calc(#{$marginLeftMobile } + #{$card-spacer-old-x} + #{$form-label-margin-bottom} + #{ $form-check-inline-margin-end}) !important;
                            background-color: $grayLighter;
                        }
                    }

                    .form-check {
                        font-size: $font-size-normal;

                        @include mq('xs') {
                            margin-left: calc(#{$marginLeftMobile * -1} - 1.05rem) !important;
                            padding: 1.467em 0 1.467em calc(#{$marginLeftMobile } + #{$card-spacer-old-x} + #{$form-label-margin-bottom} + #{ $form-check-inline-margin-end}) !important;
                        }

                        margin-left: calc(#{$marginLeft * -1} - #{$card-spacer-old-x});
                        margin-right: -#{$modal-inner-padding};
                        padding: 1.467em 0 1.467em calc(#{$marginLeft } + #{$card-spacer-old-x} + #{$form-label-margin-bottom} + #{ $form-check-inline-margin-end});
                        background-color: $grayLighter;
                        @extend .custom-control;
                        @extend .custom-checkbox;
                        min-height: calc(#{$font-size-base * $line-height-base} * 1px) !important;
                        $resultEm: em($font-size-base * $line-height-base, $font-size-base);
                        $heightPosition: (
                            calc(#{$resultEm} * .25)
                        );

                    &-input {
                        @extend .custom-control-input;

                        &:checked~.form-check-label,
                        &:not(:disabled):active~.form-check-label {
                            &::before {
                                border-color: $blue !important;
                                background-color: white;
                            }

                            &::after {
                                width: 0.6rem;
                                height: 0.6rem;
                                margin: 0.2rem;
                                background-color: $blue;
                                background-image: none !important;
                                @include border-radius($form-check-inline-margin-end);
                            }
                        }

                        &:focus~.form-check-label,
                        &:focus:not(:checked)~.form-check-label {
                            &::before {
                                border-color: $blue !important;
                                background-color: white;
                            }
                        }
                    }

                    &-label {
                        @extend .custom-control-label;
                        font-size: 1.067em;
                        justify-content: start;

                        &:after,
                        &:before {
                            top: $heightPosition;
                            /*$blue*/
                        }
                    }
                }

                .endFlow {
                    padding-right: 1em !important;

                    h3 {
                        font-size: 2.133em;
                    }

                    img+div {
                        padding-left: 3.200em;
                    }
                }
            }
        }
    }

    app-email-update {
        .button.groups {
            display: none;
        }

        .col-8.form-group {
            width: 100% !important;
            max-width: 100% !important;
        }

        margin-bottom: 2.133em;

        label {
            font-family: OpenSansBold;
            font-size: 1em;
        }
        @at-root .managementPage .optin ul li .emailConfirmedBlock .alert-warning{margin-right:0;margin-left:0;}
        .emailConfirmedBlock {
            &+ .emailConfirmedBlock{margin-top:2em;}}
    }
    
}

#langOptin {
    padding-top: 0.5em;

    li {
        padding-left: 2em;
        padding-bottom: 0.429em;
        padding-top: 0.214em;
        margin-right: 0em;

        &:first-child {
            padding-left: 0em;
        }

        a {
            text-transform: uppercase;
            line-height: 2em;
            font-size: 0.933em;
            font-family: OpenSansRegular;
            color: $blackLinkFont;
            text-decoration: none;

            &.active {
                color: $primary;
                font-family: OpenSansBold;
            }
        }
    }
}
}