app-message {
    app-summary,
    app-attachments {
        display: block;
    }
    app-summary {
        a,
        a:hover,
        a:active,
        a:focus {
            color: $blackLinkFont;
            &.active i {
                color: $green;
            }
        }
        .square,
        .square-filled {
            &:not(.square) {
                background-color: $blackLinkFont;
                color: white !important;
            }
            border: 1px solid $blackLinkFont;
            color:$blackLinkFont;
            border-radius: 4px;
            opacity: 1;
            &:not(.far):not(.fas) {
                font-family: 'OpenSansbold';
                padding: 1px 6px;
            }
            padding:2px 0px;
            font-style: normal;
            width:22px;
            height: 22px;
            text-align: center;
        }
        .transmitter,
        .receiver {
            &:not(.receiver) {
                strong {
                    font-weight: normal
                }
            }
            @include mq ('xs') {
                color: $blackLinkFont;
            }
            @include prefix((flex: 0 0 8.333em));
            @include mq ('md') {
                @include prefix((flex: 0 0 27.667em));
                flex-basis: auto;
                &:not(.flex-grow-1) {
                    max-width: 27.667em;
                }
            }
            @include mq ('xxl') {
                max-width: 80% !important;
            }
        }
        .title {
            color: $blackFont;
            @include mq('xs') {
                font-size: 0.933em;
            }
        }
        .partition{
            min-width:20px;
        }
        .receiptDate {
            @include prefix((flex: 0 0 auto));
            text-align: right;
            &.text-danger {
                margin-left: 5px
            }
        }
        .tag {
            a {
                margin-left: em(15, $font-size-base);
                &:first-child {
                    margin-left: 0em;
                }
                &+a.expiredDate {
                    margin-left: em(20, $font-size-base);
                }
            }
            .bg-danger{
                --bs-bg-opacity: .015;
        }
        }
        padding-top: .5em !important;
        @include mq("sm") {
            padding-top: 1em !important;
        }
        li {
            margin-left: 15px;
            &:first-child {
                margin-left: 0;
            }
        }
        @at-root .unread & {
            &,
            p {
                font-family: 'OpenSansBold';
                color: $blackLinkFont;
                &,
                strong {
                    font-weight: bold !important;
                }
            }
        }
    }
}