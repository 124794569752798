
.addEditUser,
.deleteUser,
.addEditPartition {
    &.modal-dialog {
        @include mq('xs') {
            & {
                margin: 0;
            }
            .modal-header,
            .modal-body,
            .modal-footer {
                padding-right: 0;
                padding-left: 0;
                &:not(.modal-header):not(.modal-footer) {
                    .alert {
                        margin-left: 0;
                        margin-right: 0;
                    }
                    .form-group {
                        margin-right: -15px;
                        margin-left: -10px; //shadow input
                    }
                    .form-check-label {
                        color: $grayDarker;
                        &::before {
                            top: 0.25rem;
                        }
                        &::after {
                            top: calc(0.25rem + 2px);
                        }
                    }
                }
            }
        }
        line-height: $line-height-base;
        .modal-header {
            h3 {
                margin-bottom: 0em;
            }
        }
        .card {
            &-header.text-danger {
                background-color: $dangerCom;
            }
        }
        .modal-content {
            border: 0 !important;
            background-color: $whiteBroken;
            p {
                font-size: 1em;
                color: $blackLinkFont;
                font-family: OpenSansRegular;
            }
            form {
                .mobileTypeHead {
                    & {
                        max-width: calc(100% - 30px);
                    }
                    .dropdown-item {
                        white-space: break-spaces;
                    }
                }
                label .invalid-feedback span {
                    margin-left: .5em;
                }
                p.required {
                    margin-top: 0;
                    margin-bottom: 0;
                    margin-right: -15px;
                }
                label,
                .form-control-plaintext,
                .form-check-label {
                    color: $grayDarker;
                    font-family: OpenSansRegular;
                    margin-bottom: 0;
                    &:not(label) {
                        padding: .375rem;
                    }
                }
                .form-check-label {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
                &.was-validated .form-check-input:valid~.form-check-label::before,
                .form-check-input.is-valid~.form-check-label::before {
                    border-color: $primary;
                }
                &.was-validated .form-check-input:valid:checked~.form-check-label::before,
                .form-check-input.is-valid:checked~.form-check-label::before {
                    border-color: $primary;
                    background-color: $primary;
                }
                textarea {
                    padding: 1.133em 1.533em;
                }
                .alert-danger[role=alert] {
                    margin-left: 0px;
                    width: auto;
                    border: 1px solid $red;
                    background-color: #fff;
                    h4 {
                        margin-bottom: 0em;
                        display: inline-block;
                    }
                    &>h4+div {
                        padding: 0em !important;
                    }
                    ul,
                    p {
                        margin-bottom: 0em;
                        margin-top: 0em;
                    }
                }
                .alert {
                    & #alerts {
                        @include mq('sm') {
                            position: relative;
                        }
                    }
                    &:first-child {
                        margin-bottom: 0em;
                    }
                    padding: 0;
                    border-radius: 0;
                    border: 0px solid;
                    &-info,
                    &-warning,
                    &-danger {
                        color: $blackFont;
                        font-size: 1em;
                        .fas:not(.fa-chevron-down):not(.fa-chevron-up):not(.fa-caret-down):not(.fa-caret-up):not(.fa-exclamation-circle):not(.fa-times) {
                            color: $blue;
                            font-size: 1.667em;
                            font-size: 1.667em;
                            margin-right: 0.5em;
                            margin-left: 0.5em;
                            margin-top: 0.25em;
                        }
                    }
                }
            }
            .btn-primary {
                font-size: 0.933em;
            }
            .btn-link {
                color: $blackLinkFont;
                font-family: 'OpenSansBold';
                margin-left: 1.400em;
                @include mq('xs') {
                    margin-left: 3px !important;
                }
                &:hover,
                &:focus {
                    background-color: transparent !important;
                }
            }
        }
    }
}
