    app-main,
    app-leftnav {
        @include mq ('sm') {
            padding-top: 2.133em;
        }
    }

    app-main {
        min-height: 100vh;

        app-search-bar {
            @include mq("sm") {
                padding-left: 1em;
            }

            @include mq("md") {
                padding-left: 0em;
            }
        }

        &.col-12 {
            padding-left: 0;
            padding-right: 0;
        }

        .alert {
            margin-right: 15px;
            margin-left: 15px;
            border-radius: 4px;

            &.alert {
                &-info {
                    background-color: rgba($blue, 0.1);
                }

                &-danger {
                    background-color: rgba($red, 0.1);
                }

                &-success {
                    background-color: rgba($primary, 0.1);
                }
            }

            padding-left: 0.733em;
            padding-right: 0.733em;

            &.alert-success p:before {
                font: normal normal 900 1.333em/1 'Font Awesome 6 Free';
                content: unicode("f00c");
                color: $primary;
                font-size: 1.333em;
                margin-right: 0.667em
            }

            strong {
                font-family: OpenSansBold;
                display: ruby;
                display: contents;
            }
        }

        @include mq('sm') {
            padding-left: $app-main-pl !important;
            padding-right: $app-main-pl !important;
        }

        /*@include mq("xxxxl") {
        padding-left: $app-main-ps-lg !important;
    }*/
        background-color: $whiteBroken;

        &.no-after {
            padding: 0 !important
        }

        /* &:not(.no-after)::after {
        position: absolute;
        background-color: $whiteBroken;
        left: 0px;
        @at-root {
            @supports (-webkit-appearance: none) {
                & {
                    left: -1px !important; // bug fix for chrome
                }
            }
        }
        top: 0;
        transform: translateX(100%);
        height: 100%;
        overflow: hidden;
        width: 100%;
        content: "";
    }*/
    }

    app-actions {
        border-bottom: 2px solid $blackLinkFont;
        padding-bottom: 1em;

        /*@include mq("xxxxl") {
        padding-left: $app-main-ps-lg !important;
        margin-left: calc(#{$app-main-ps-lg} * -1) !important;
    }*/
        button.btn-primary {
            font-family: OpenSansRegular !important;
        }

        app-select-items-by-page {
            p {
                margin-bottom: 0em;
            }

            span[role="status"] {
                strong {
                    font-weight: normal;
                    font-family: OpenSansSemiBold;
                    color: $blackLinkFont;
                }
            }
        }
    }

    app-messages {
        & {
            display: block;
            padding-right: 0;
        }

        .cards>li {
            & {
                @include mq("xs") {
                    padding-bottom: .5em;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                padding-bottom: 1em;
                background-color: transparent;
                border-bottom-color: rgba($grayLine, 0.17);
            }

            .card-header,
            .card-body,
            .card-footer {
                border: 0px;
                background-color: transparent;
                padding: 0;

                &:not(.card-body):not(.card-footer) {
                    padding-top: 1em;
                    padding-left: 15px;
                }
            }

            &.card a.col {
                color: $blackFont;

                @include mq("xs") {
                    padding-left: 0;
                }

                @include mq("sm") {
                    margin: auto 0px auto 0px;
                    padding: 0 0 0 16px;
                    width: calc(100% - 20px);
                }
            }
        }
    }