//variable
$input-btn-line-height-lg: $line-height-lg !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-line-height-sm: $line-height-sm !default;
// Forms

$label-margin-bottom: .5rem !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: .3rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;
$form-check-inline-input-margin-x: .3125rem !default;

$form-grid-gutter-width: 10px !default;
$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-forms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$custom-control-gutter: .5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-cursor: null !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: $input-bg !default;

$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: $input-border-width !default;

$custom-control-label-color: null !default;

$custom-control-indicator-disabled-bg: $input-disabled-bg !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg: rgba($primary, .5) !default;
$custom-control-indicator-checked-box-shadow: null !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color: $input-focus-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow: null !default;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: null !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;



$custom-select-padding-y: $input-padding-y !default;
$custom-select-padding-x: $input-padding-x !default;
$custom-select-font-family: $input-font-family !default;
$custom-select-font-size: $input-font-size !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight !default;
$custom-select-line-height: $input-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $input-bg !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800 !default;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background: escape-svg($custom-select-indicator) right $custom-select-padding-x center / $custom-select-bg-size no-repeat !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-position: center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width: $input-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-width: $input-focus-width !default;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

$custom-select-padding-y-sm: $input-padding-y-sm !default;
$custom-select-padding-x-sm: $input-padding-x-sm !default;
$custom-select-font-size-sm: $input-font-size-sm !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-select-padding-y-lg: $input-padding-y-lg !default;
$custom-select-padding-x-lg: $input-padding-x-lg !default;
$custom-select-font-size-lg: $input-font-size-lg !default;
$custom-select-height-lg: $input-height-lg !default;

$custom-range-track-width: 100% !default;
$custom-range-track-height: .5rem !default;
$custom-range-track-cursor: pointer !default;
$custom-range-track-bg: $gray-300 !default;
$custom-range-track-border-radius: 1rem !default;
$custom-range-track-box-shadow: inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width: 1rem !default;
$custom-range-thumb-height: $custom-range-thumb-width !default;
$custom-range-thumb-bg: $component-active-bg !default;
$custom-range-thumb-border: 0 !default;
$custom-range-thumb-border-radius: 1rem !default;
$custom-range-thumb-box-shadow: 0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg: $gray-500 !default;

$custom-file-height: $input-height !default;
$custom-file-height-inner: $input-height-inner !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-focus-box-shadow !default;
$custom-file-disabled-bg: $input-disabled-bg !default;

$custom-file-padding-y: $input-padding-y !default;
$custom-file-padding-x: $input-padding-x !default;
$custom-file-line-height: $input-line-height !default;
$custom-file-font-family: $input-font-family !default;
$custom-file-font-weight: $input-font-weight !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
	en: "Browse"
	) !default;


// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border-color` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus($ignore-warning: false) {
	&:focus {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		border-color: $input-focus-border-color;
		outline: 0;

		@if $enable-shadows {
			@include box-shadow($input-box-shadow, $input-focus-box-shadow
		);
	}

	@else {
		// Avoid using mixin so we can pass custom focus shadow properly
		box-shadow: $input-focus-box-shadow;
	}
}

@include deprecate("The `form-control-focus()` mixin", "v4.4.0", "v5", $ignore-warning);
}



// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
	display: block;
	width: 100%;
	height: $input-height;
	padding: $input-padding-y $input-padding-x;
	font-family: $input-font-family;
	@include font-size($input-font-size);
	font-weight: $input-font-weight;
	line-height: $input-line-height;
	color: $input-color;
	background-color: $input-bg;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;

	// Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
	@include border-radius($input-border-radius, 0);

	@include box-shadow($input-box-shadow);
	@include transition($input-transition);

	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}

	// Customize the `:focus` state to imitate native WebKit styles.
	@include form-control-focus($ignore-warning: true);

	// Placeholder
	&::placeholder {
		color: $input-placeholder-color;
		// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
		opacity: 1;
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled,
	&[readonly] {
		background-color: $input-disabled-bg;
		// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
		opacity: 1;
	}
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	&.form-control {
		appearance: none; // Fix appearance for date inputs in Safari
	}
}

select.form-control {

	// Remove select outline from select box in FF
	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 $input-color;
	}

	&:focus::-ms-value {
		// Suppress the nested default white text on blue background highlight given to
		// the selected option text when the (still closed) <select> receives focus
		// in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
		// match the appearance of the native widget.
		// See https://github.com/twbs/bootstrap/issues/19398.
		color: $input-color;
		background-color: $input-bg;
	}
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
	display: block;
	width: 100%;
}


//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
	padding-top: add($input-padding-y, $input-border-width);
	padding-bottom: add($input-padding-y, $input-border-width);
	margin-bottom: 0; // Override the `<label>/<legend>` default
	@include font-size(inherit); // Override the `<legend>` default
	line-height: $input-line-height;
}

.col-form-label-lg {
	padding-top: add($input-padding-y-lg, $input-border-width);
	padding-bottom: add($input-padding-y-lg, $input-border-width);
	@include font-size($input-font-size-lg);
	line-height: $input-line-height-lg;
}

.col-form-label-sm {
	padding-top: add($input-padding-y-sm, $input-border-width);
	padding-bottom: add($input-padding-y-sm, $input-border-width);
	@include font-size($input-font-size-sm);
	line-height: $input-line-height-sm;
}


// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: $input-padding-y 0;
	margin-bottom: 0; // match inputs if this class comes on inputs with default margins
	@include font-size($input-font-size);
	line-height: $input-line-height;
	color: $input-plaintext-color;
	background-color: transparent;
	border: solid transparent;
	border-width: $input-border-width 0;

	&.form-control-sm,
	&.form-control-lg {
		padding-right: 0;
		padding-left: 0;
	}
}


// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// Repeated in `_input_group.scss` to avoid Sass extend issues.

.form-control-sm {
	height: $input-height-sm;
	padding: $input-padding-y-sm $input-padding-x-sm;
	@include font-size($input-font-size-sm);
	line-height: $input-line-height-sm;
	@include border-radius($input-border-radius-sm);
}

.form-control-lg {
	height: $input-height-lg;
	padding: $input-padding-y-lg $input-padding-x-lg;
	@include font-size($input-font-size-lg);
	line-height: $input-line-height-lg;
	@include border-radius($input-border-radius-lg);
}

// stylelint-disable-next-line no-duplicate-selectors
select.form-control {

	&[size],
	&[multiple] {
		height: auto;
	}
}

textarea.form-control {
	height: auto;
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
	margin-bottom: $form-group-margin-bottom;
}

.form-text {
	display: block;
	margin-top: $form-text-margin-top;
}


// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -$form-grid-gutter-width * .5;
	margin-left: -$form-grid-gutter-width * .5;

	>.col,
	>[class*="col-"] {
		padding-right: $form-grid-gutter-width * .5;
		padding-left: $form-grid-gutter-width * .5;
	}
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
	position: relative;
	display: block;
	padding-left: $form-check-input-gutter;
}

.form-check-input {
	position: absolute;
	margin-top: $form-check-input-margin-y;
	margin-left: -$form-check-input-gutter;

	// Use [disabled] and :disabled for workaround https://github.com/twbs/bootstrap/issues/28247
	&[disabled]~.form-check-label,
	&:disabled~.form-check-label {
		color: $text-muted;
	}
}

.form-check-label {
	margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-inline {
	display: inline-flex;
	align-items: center;
	padding-left: 0; // Override base .form-check
	margin-right: $form-check-inline-margin-x;

	// Undo .form-check-input defaults and add some `margin-right`.
	.form-check-input {
		position: static;
		margin-top: 0;
		margin-right: $form-check-inline-input-margin-x;
		margin-left: 0;
	}
}


// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.



// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

	// Because we use flex, the initial sizing of checkboxes is collapsed and
	// doesn't occupy the full-width (which is what we want for xs grid tier),
	// so we force that here.
	.form-check {
		width: 100%;
	}

	// Kick in the inline
	@include media-breakpoint-up(sm) {
		label {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
		}

		// Inline-block all the things for "inline"
		.form-group {
			display: flex;
			flex: 0 0 auto;
			flex-flow: row wrap;
			align-items: center;
			margin-bottom: 0;
		}

		// Allow folks to *not* use `.form-group`
		.form-control {
			display: inline-block;
			width: auto; // Prevent labels from stacking above inputs in `.form-group`
			vertical-align: middle;
		}

		// Make static controls behave like regular ones
		.form-control-plaintext {
			display: inline-block;
		}

		.input-group,
		.custom-select {
			width: auto;
		}

		// Remove default margin on radios/checkboxes that were used for stacking, and
		// then undo the floating of radios and checkboxes to match.
		.form-check {
			display: flex;
			align-items: center;
			justify-content: center;
			width: auto;
			padding-left: 0;
		}

		.form-check-input {
			position: relative;
			flex-shrink: 0;
			margin-top: 0;
			margin-right: $form-check-input-margin-x;
			margin-left: 0;
		}

		.custom-control {
			align-items: center;
			justify-content: center;
		}

		.custom-control-label {
			margin-bottom: 0;
		}
	}
}