.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    &.firstLoading {
        background-color: #fff;
    }
}

.dialog {
    &.firstLoading {
        display: none;
    }
    z-index: 10000;
    position: fixed;
    right: 0;
    left: 0;
    top: 50px;
    margin-right: auto;
    margin-left: auto;
    &:not(.loadingDialog) {
        @include mq('xs') {
            height: 90%;
            overflow-y: scroll;
        }
        @include mq('sm') {
            min-height: 200px;
        }
    }
    width: 90%;
    max-width: 520px;
    background-color: #fff;
    padding: 12px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 13px 19px 2px rgba(0, 0, 0, 0.14),
    0 5px 24px 4px rgba(0, 0, 0, 0.12);
    h1 {
        color: $blueFont;
        margin-top: 0em;
        margin-bottom: 20px;
        img {
            vertical-align: inherit;
        }
    }
    .btn.btn-primary {
        background: $blueFont;
    }
    &.loadingDialog {
        @include vertical-align();
        max-width: 300px !important;
        min-height: 150px !important;
        padding-top: 2.5em !important;
        position: fixed !important;
        z-index: 9999;
    }
}

@media (min-width: 768px) {
    .dialog {
        top: 40px;
    }
}

.dialog__close-btn {
    border: 0;
    background: none;
    color: #2d2d2d;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 1.2em;
}