                app-attachments,
                ul.attachmentListForm {
                    li {
                        app-message {
                            display: flex;
                        }
                        @at-root {
                            app-messages & {
                                max-width: 100%;
                                @include mq("sm") {
                                    max-width: 40%;
                                }
                                @include mq("xxxl") {
                                    max-width: 30%;
                                }
                                &.text-attachment {
                                    max-width: 100%;
                                }
                                &>a {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                            app-forms-bidirectional &,
                            app-manual-publication & {
                                display: -ms-inline-flexbox !important;
                                padding-bottom: .833em;
                                margin-top: 0em;
                                margin-bottom: 0em;
                                &>a {
                                    i.fa-trash-alt {
                                        margin-right: 0 !important;
                                    }
                                    padding-right: .95em;
                                    app-attachment {
                                        width: 77%;
                                        align-items: center;
                                    }
                                    span.file-size {
                                        white-space: nowrap;
                                        margin-left: .5em;
                                    }
                                    span:not(.visually-hidden):not(.file-size) {
                                        @include mq("sm") {
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        }
                                    }
                                }
                                max-width: 100%;
                                @include mq("lg") {
                                    max-width: 50%;
                                    &>a {
                                        app-attachment {
                                            width: 64%;
                                        }
                                    }
                                }
                                @include mq("xl") {
                                    max-width: 50%;
                                    &>a {
                                        app-attachment {
                                            width: 72%;
                                        }
                                    }
                                }
                                @include mq("xxl") {
                                    &>a {
                                        app-attachment {
                                            width: 77%;
                                        }
                                    }
                                }
                                @include mq("xxxl") {
                                    max-width: 50%;
                                    &>a {
                                        app-attachment {
                                            width: 81%;
                                        }
                                    }
                                }
                                @include mq("xxxxl") {
                                    max-width: 35%;
                                    &>a {
                                        app-attachment {
                                            width: 75%;
                                        }
                                    }
                                }
                            }
                        }
                        .btn.btn-link {
                            text-decoration: underline;
                        }
                        a {
                            display: block;
                            color: $blackFont;
                            border-radius: 10px;
                            padding-left: 0.950em;
                            padding-right: 1.267em;
                            border: 1px solid $grayMiddle;
                            app-attachment {
                                span {
                                    word-break: break-all;
                                    @include mq('lg') {
                                        word-break: normal;
                                    }
                                }
                                i.far,
                                i.fas {
                                    color: $blackFont;
                                    font-size: em(20, $font-size-base);
                                    margin-right: 0.6em;
                                    margin-top: .5em;
                                    &:not(.fas) {
                                        margin-bottom: .5em;
                                    }
                                    &.fa-file-pdf {
                                        color: $red;
                                    }
                                    &.fa-file-word {
                                        color: $blue;
                                    }
                                    &.fa-file-excel {
                                        color: $green;
                                    }
                                    &.fa-file-archive {
                                        color: $yellow;
                                    }
                                    &.fa-file-powerpoint {
                                        color: $orangeDark;
                                    }
                                }
                            }
                        }
                    }
                }