@charset "UTF-8";

//component.scss
.was-validated .form-control:valid,
form .form-control.is-valid,
.was-validated :not(ngb-datepicker-navigation-select) .form-select:valid,
.form-select.is-valid {
    background-image: none !important;
    padding-right: 0px;
}

.form-select {
    background-image:#{$custom-select-indicator};
}

.form-control,
.form-select {
    font-size: 1em;
}

.btn {
    font-size: 1em;
}

.row>* {
    padding-right: 0;
    padding-left: 0;
}

.btn-primary {
    background-color: $btnPrimary;
    border: none;
}

.returnLink {
    font-size: 14px;
    text-decoration: underline !important;
    display: block;
    width: 100%;

    &:before {
        content: unicode("f104");
        font: normal normal 900 15px/1 'Font Awesome 6 Free';
        padding-right: 0.583em;
        text-decoration: none !important;
        display: inline-block;
    }
}

.navbar {
    justify-content: flex-end;
    padding: 0;

    .navbar-toggler {
        font-size: 1em;
    }

    .fa-ul {
        margin-top: 0;
        margin-bottom: 0;
    }
}

//modal for design
.modal-header {
    justify-content: space-between;

    button.close {
        background: transparent;
        border: none;
    }

}

.modal-body {
    li.form-check {
        padding-left: 0;

        .form-check-label {
            padding: 0;
            width: 1px;

            &::before {
                position: relative;
            }
        }
    }
}

.modalNavbar {
    &.search-form {
        .modal-content {
            form {
                color: $blackFont;

                label {
                    font-size: $font-size-normal;
                }

                .btn-primary {
                    border-radius: 0.25rem !important;
                }

                .input-group .btn-primary {
                    border-bottom-left-radius: 0 !important;
                    border-top-left-radius: 0 !important;

                    i {
                        font-size: 1.5em;
                    }
                }

                .btn-link {
                    color: $primary;
                    text-decoration: underline;
                    font-size: $font-size-normal;
                    font-family: OpenSansSemibold;
                }

                label+.form-select {
                    /* reset */
                    margin: 0;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, $greenFont, $greenFont);
                    background-position: calc(100% - 20px) calc(1em + 5px), calc(100% - 15px) calc(1em + 5px), 100% 0;
                    background-size: 5px 5px,
                    5px 5px,
                    calc(#{$input-height-inner} + #{$input-height-border} + #{$input-height-border}) calc(#{$input-height-inner} + #{$input-height-border});
                    background-repeat: no-repeat;
                }
            }
        }
    }

    border: 0px;
    border-radius: 0px;

    .modal-dialog {
        background-color: transparent;

        .modal-content {
            border: 0px;
            border-radius: 0px;
            background-color: #fff;
            color: $blackFont;
        }

        .modal-header {
            @extend .modal-content;
            padding-bottom: .721em;
            background: $gradientDefault;
            background: -moz-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            background: -webkit-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            background: linear-gradient(to right, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#{$gradientStart}', endColorstr='#{$gradientStop}', GradientType=1)";
            padding-top: .971em;

            h3,
            button.close {
                color: #fff;
                font-family: $font-family-base;
                font-size: 1.333em;
                font-weight: normal;
                background: transparent;
                border: none;

                &:not(h3) {
                    margin: 0;
                    padding: 0;
                    opacity: 1;
                    font-size: 2.5em;
                }
            }
        }

        .modal-body {
            @extend .modal-content;
            padding-top: 1.167em;

            h3,
            h4 {
                color: #fff;
            }

            .btn {
                font-size: 1em;
            }

            .navbar-collapse {
                &.collapse.expand {
                    display: block !important;
                }

                &.collapse.collapsed {
                    display: none !important;
                }
            }

            .navbar-nav {
                .nav-item {
                    line-height: 16px;
                    padding-top: 2px;

                    &:before {
                        content: unicode("f105");
                        font: normal normal 900 15px/1 'Font Awesome 6 Free';
                        padding-right: 1em;
                    }

                    a {
                        color: #fff;
                        text-transform: uppercase;
                        line-height: 2.813em;
                    }

                    &>ul {
                        li {
                            border-left: 1px solid #fff;

                            &:first-child {
                                border-left: 0;
                                padding-left: 0;
                                margin-right: 0;
                                padding-right: .5em;
                            }

                            padding-left:.5em;

                            &.active {
                                a {
                                    background-color: #fff;
                                    color: $tableBlue;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        padding: 0;
                        margin: 0;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &.modal.fade.right .modal-dialog {
        right: -$sm;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }

    &.modal.fade.right.show .modal-dialog {
        right: 0;
    }
}

.paginationNav {

    p,
    button {
        font-size: 1em;
    }

    @include clearfix;
    display: block;

    .noResult {
        font-weight: bold;
    }

    ul.pagination {
        @include margin(1.083em, 0.833em, 1.083em, 0.833em);

        @include mq('sm') {
            margin-right: 0em;
        }
    }

    li.page-item {
        margin-left: .5em;

        &:first-child {
            margin-left: 0em;
        }

        &.previous .fas {
            margin-right: .5em
        }

        &.next .fas {
            margin-left: .5em
        }
    }

    .dropdown {
        margin-left: .5em;

        .btn,
        .dropdown-item {
            &:not(.btn) {
                padding: .25em .25em !important;
            }

            &:not(.dropdown-item) {
                padding-right: 4.429em !important;
                padding-left: 0.857em !important;
            }

            font-size: $font-size-normal;

            &:hover:not(.dropdown-toggle),
            &:focus:not(.dropdown-toggle) {
                background: #363c46;
                color: #fff;
            }
        }

        .btn-outline-dropdown {
            color: $blackFont;
            background-color: #0000;
            background-image: none;
            border-color: $grayLine;
        }

        .dropdown-toggle,
        .dropdown-menu {
            width: 80px !important;
            max-width: 80px !important;
            min-width: 80px !important;

            &:not(.dropdown-toggle) {
                font-size: 1em;
                text-align: center;
            }

            &:not(.dropdown-menu) {
                font-size: 1em;
                border-radius: 4px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-color: #fff;
                background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, $greenFont, $greenFont);
                background-position: calc(100% - 17px) calc(1em + 1px), calc(100% - 12px) calc(1em + 1px), 100% 0;
                background-size: 5px 5px,
                5px 5px,
                2rem #{$input-height-inner-btn};
                background-repeat: no-repeat;

                &:after {
                    display: none
                }
            }
        }
    }

    .page-link,
    button:not(.dropdown-toggle):not(.dropdown-item):not(.btn.btn-primary) {
        @include border-radius;
        background: #fff !important;
        border: 1px solid $grayLine !important;
        color: $blackFont !important;
        margin-left: .5em;

        &:first-child {
            margin-left: 0em;
        }

        &:hover,
        &:focus {
            color: $blueFocus !important;
            background-color: #eee !important;
            border-color: #ddd !important;
        }

        span:not(.visually-hidden)+i,
        i+span {
            margin-left: .5em;
        }

        &:not(button) {
            font-weight: bold;
        }
    }

    &.bottom {
        ul.pagination {
            margin-top: 0em;
        }

        button {
            line-height: 32px !important;
            width: 32px !important;
            height: 32px !important;
        }
    }

    .input-group .btn {
        margin-left: 0;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
}

app-search-bar {
    .search-expanded & {
        margin-top: 1em;
        padding: 1.250em 0.833em;
    }

    .input-group .form-control {
        @include mq("xs") {
            height: auto;
            border-bottom: 0px
        }
    }

    @at-root .input-group {
        &:not(.has-validation) {
            &>:not(:last-child) {
                &>.btn {
                    @include mq('sm') {
                        border-top-right-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                    }
                }
            }
        }

        &.focused {
            -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 137, 67, 0.25);
            -moz-box-shadow: 0 0 0 0.2rem rgba(1, 137, 67, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(1, 137, 67, 0.25);
            border-radius: 0.25rem;

            button:focus {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }

        .form-control {
            border-color: #fff;

            &:focus {
                //border-right: 0px;
                /*  -webkit-box-shadow: -.2rem 0 0 0.2rem rgba(1, 137, 67, 0.25);
                -moz-box-shadow: -.2rem 0 0 0.2rem rgba(1, 137, 67, 0.25);
                box-shadow: -.2rem 0 0 0.2rem rgba(1, 137, 67, 0.25);
                */
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }

        & {
            margin-top: 1px;

            @include mq ('sm') {
                margin-top: 0px;
            }

            button.btn {
                color: $primary;
                background-color: #fff;
                height: auto;

                @include mq ('xs') {
                    font-size: em(18, $font-size-base);
                    padding-left: 0;
                    padding-right: 0;
                }

                @include mq ('sm') {
                    color: #fff;
                    border-top-right-radius: 0.25rem;
                    border-bottom-right-radius: 0.25rem;
                }

                &:not(.reset) {
                    @include mq ('sm') {
                        background-color: $primary;
                    }
                }

                &:last-child {
                    border-top-right-radius: 0.25rem !important;
                    border-bottom-right-radius: 0.25rem !important;
                }

                border: 0px;

                i {
                    margin-left: 0em;

                    &.fa-search,
                    &.fa-times {
                        font-size: 1em;
                        margin-left: .10em;

                        &:not(.fa-times) {
                            margin-left: .4335em;
                        }
                    }
                }

                &.reset {
                    color: $red;
                    border-color: $disable !important;
                    border-top-right-radius: 0.25rem !important;
                    border-bottom-right-radius: 0.25rem !important;

                    @include mq ('xs') {
                        font-size: em(18, $font-size-base);
                        padding-left: 0.733em;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.searchElements {
    padding: 0em 0.833em 1.116em;

    main {
        @include padding(0em, 0em, 0.333em, 0em);
        margin-right: -4px;

        @include mq('sm') {
            /* background-color: $whiteBroken;*/
            margin-right: 0px;
        }

        nav,
        .actionTable {
            color: #fff;
            padding: 0em 0em 1.167em 1em;
            margin: 0em;

            &:not(.actionTable) {
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 0em;

                a {
                    font-size: $font-size-normal;
                }
            }
        }

        @include mq('sm') {
            .filterPredefined a {
                text-decoration: underline;
            }
        }

        .actionTable {
            background-color: $tableBlue;
            @include clearfix;
        }

        ul {
            padding: 0em;
            margin: 0em;

            li {
                padding: 0em;
                margin: 0em;
            }

            li:not(.action) {
                font-size: 1em;
            }
        }

        button {
            i {
                margin-left: .5em;
            }
        }
    }

    @include mq('sm') {
        padding-left: 0em;
        padding-right: 0em;
    }

    .searchResult {
        @include clearfix;
        margin-top: 0em;
        padding-top: 0em;
        margin-bottom: 1em;

        @include mq('sm') {
            border-bottom: 1px solid $grayLine;
            //padding-top: 1.668em;
            padding-bottom: 10px;
        }

        .filterElements {
            a {
                color: $blackFont;
                font-size: $font-size-normal;
                text-decoration: none;

                .fas,
                .far {
                    text-decoration: none;
                    margin-right: 0.5em;
                }
            }

            .badge {
                font-size: 1em;
                line-height: 1.333em;
                margin-left: .5em;
            }

            #filterElementsAvailable {
                margin-top: 1em;
            }
        }

        .filterResult {
            margin-top: 1.085em;
            /* background-color: $grayLight;*/
            padding-top: 0.917em;
            padding-left: .75em;
            padding-bottom: 1.333em;

            @include mq('sm') {
                margin-top: 0;
                padding-top: 0em;
                padding-bottom: .5em;
            }

            h3 {
                font-family: 'OpenSansRegular';
                font-size: $font-size-normal;

                a,
                .badge {
                    font-size: 1em;

                    &:not(a) {
                        padding: 0.5em .7em;
                    }

                    &:not(.badge) {
                        font-weight: normal;
                        text-decoration: underline;
                        margin-left: 1em;
                    }
                }
            }
        }

        p {
            margin-bottom: .5em;
        }

        a {
            text-decoration: underline;
        }

        strong {
            font-family: OpenSansBold;
            font-size: 1.333em;
        }

        button {
            margin-top: .75em;
        }

        label.reset {
            color: $red;
            border: $form-select-border-width solid $form-select-border-color;
            font-size: 1em;
            margin-bottom: 0 !important;
            padding-left: 10px;
            padding-right: 10px;
            border-top-right-radius: 0.25em;
            border-bottom-right-radius: 0.25em;
            background-color: $form-select-bg;
            cursor: pointer;

            i {
                padding-top: 5px;
                margin-bottom: 0 !important;
            }

            @include mq('xs') {
                font-size: em(18, $font-size-base);
                padding-left: 0.733em;
                padding-right: 0;
            }
        }
    }
}

app-search-filter-bar,
app-filter-bar {
    $marginRightFilterBar: 2.417em;

    label {
        font-size: $font-size-normal;
        padding-bottom: 0.3em;
    }

    .form-horizontal {
        legend {
            font-size: 1.333em;
            margin-bottom: .667em;
            padding-top: 0.584em;
        }

        .form-check {
            padding-left: 2rem;
        }

        .input-group {
            margin-top: 0;

            button.btn {
                height: auto !important;
            }
        }

        &.ng-invalid {
            .invalid-feedback {
                display: inline-block;
                color: $red;
                font-size: 1em;
                width: auto;
                margin-bottom: 0em;
                margin-top: 0em;
                margin-left: .25em;
                margin-right: .25em;
            }
        }
    }

    .form-inline {
        align-items: flex-end;

        legend {
            font-size: $font-size-normal;
        }

        label {
            justify-content: flex-start;

            :not(.form-check-label):not(.invalid-feedback) {
                margin-bottom: 0.667em;
            }
        }

        .form-check {
            justify-content: flex-start !important;
            padding-left: 1rem !important;

            &-label {
                padding-bottom: 0em;
            }
        }

        &.ng-invalid {
            .invalid-feedback {
                display: inline-block;
                color: $red;
                font-size: 1em;
                width: auto;
                margin-bottom: 0em;
                margin-top: 0em;
                margin-left: .25em;
                margin-right: .25em;
            }

            .form-group,
            fieldset {
                &:not(.form-group) {
                    &+fieldset {
                        align-self: start;
                    }
                }

                &:not(fieldset) {
                    vertical-align: top;
                }
            }

            .filter-btn {
                align-self: center;
            }
        }

        .form-group,
        fieldset {
            &:not(.form-group) {
                &+fieldset {
                    width: calc(50% - 2 * #{$marginRightFilterBar});
                }
            }

            margin-right: $marginRightFilterBar;

            & .d-flex {
                .form-group {
                    flex-basis: calc(50% - #{$marginRightFilterBar} / 2);
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .input-group {
            button.btn {
                margin-top: 0em;

                i {
                    font-size: 1.5em;
                    margin-left: 0em;
                }
            }

            button.btn.smallBtn {
                margin-top: 0em;

                i {
                    font-size: 1em;
                    margin-left: 0em;
                }
            }
        }



        .form-select {
            &:focus {
                border-color: $greenFocus !important;
                box-shadow: 0 0 0 0.2rem rgba(1, 137, 67, 0.25) !important;
            }

            width: 90%;
        }

        .filter-btn {
            .btn {
                font-size: $font-size-normal;
                margin-top: 0em;
                height: $input-height;
                padding-left: 1.75em;
                padding-right: 1.833em;
            }
        }
    }
}

app-communication {

    /* @include mq('xs') {
            position: absolute;
            z-index: 1;
            right: 0;
        }*/
    .fixed {
        z-index: 2;
    }

    @include mq('md') {
        margin-right: $app-main-pl !important;
    }

    .alert {
        & #alerts {
            @include mq('sm') {
                position: relative;
            }
        }

        &:first-child {
            margin-bottom: 0em;
        }

        padding: 0;
        border-radius: 0;
        border: 0px solid;

        &-info,
        &-warning,
        &-danger {
            color: $blackFont;

            .fas:not(.fa-chevron-down):not(.fa-chevron-up):not(.fa-caret-down):not(.fa-caret-up):not(.fa-exclamation-circle):not(.fa-times) {
                color: $blue;
                font-size: 1.667em;
                margin-right: .5em;
            }

            &:not(.alert-warning) {
                margin-right: - $app-main-pl !important;

                &:not(.alert-info) {
                    background: $dangerCom;

                    li {
                        line-height: 1em;
                    }

                    .fa-exclamation-circle,
                    .removeAlert {
                        @include mq('xs') {
                            margin-right: 2em !important;
                        }

                        color: $danger;
                        font-size: 1.667em;
                        margin-right: .5em;

                        &:not(.fa-exclamation-circle) {
                            font-size: 1em;

                            i {
                                color: $blackFont;
                                font-size: 1.5em;
                            }

                            margin-right: 1em;
                            margin-left: 1.5em;
                        }
                    }
                }

                @include mq('sm') {
                    padding-top: 1.583em;
                }

                padding: 1em 0.833em 0.333em;

                p {
                    font-size: $font-size-normal;
                }
            }

            &:not(.alert-info):not(.alert-danger) {
                background: transparent;

                h2 {
                    background-color: $yellow;
                }
            }
        }

        h2 {
            margin: 0em;
            padding: 0em;
            font-size: em(14, $font-size-base);
            font-family: 'OpenSansBold';
            color: $blackLinkFont;
            width: auto;

            @include mq('md') {
                @include resetHeight(43px !important);
                overflow-y: hidden;
                margin-top: -43px;
            }

            a {
                line-height: 1.6em;

                .fa-bell {
                    color: $orange;
                    margin-right: 0.667em;
                }

                padding: .2em 0.833em 0.333em;

                @include mq('sm') {
                    padding-top: 1em;
                    padding-bottom: 0.666em;
                }

                color: $blackLinkFont;
                position: relative;

                &:focus {
                    outline: #2574a9 1px auto;
                }
            }
        }

        .w-100 {
            -ms-flex: 0 1 auto !important;
            flex: 0 1 auto !important;

        }

        .card {
            border: 0px;
            border-radius: 0;
            background: white;
            @include padding(0, 0, 6px, 0);

            @include mq('sm') {
                padding-bottom: 0px;
                position: absolute;
                min-width: 26.500em;
                right: 0;
                z-index: 99;
            }

            @include margin(0, 0, 0, 0);

            .card-body {
                padding: 1.667em 0.833em 2.083em;

                @include mq('sm') {
                    padding-left: 1.999em;
                    padding-top: 1.75em;
                }

                box-shadow: -4px 4px 16px 0px rgba(212, 211, 222, 1);

                ul {
                    margin: 0em;
                    padding: 0em;

                    li {
                        padding-top: 0.833em;

                        a {
                            span {
                                line-height: 1.6em;
                            }

                            color: $blackFont;
                            margin: 0em;
                            padding: 0em;
                            font-size: $font-size-normal;

                            strong {
                                color: $greenFont;
                                text-decoration: underline;
                            }
                        }

                        &:first-child {
                            padding-top: 0em;
                        }
                    }
                }
            }
        }
    }
}

.stepper {
    margin: 0;
    padding: 0;

    .stepper-item {
        width: 62px;
        height: 4px;
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        background-color: $grayLight;
        margin-left: 1.067em;

        :first-child {
            margin-left: 0;
        }

        &.active {
            width: 80px;
            height: 8px;
            background: $gradientDefault;
            background: -moz-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            background: -webkit-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            background: linear-gradient(to right, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
            filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#{$gradientStart}', endColorstr='#{$gradientStop}', GradientType=1)";
        }
    }
}