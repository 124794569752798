// Mediaqueries
@mixin mq($canvas) {
    @if $canvas==xs {
        @media only screen and (max-width: $xs) {
            @content;
        }
    }
    @else if $canvas==sm {
        @media only screen and (min-width: $sm) {
            @content;
        }
    }
    @else if $canvas==md {
        @media only screen and (min-width: $md) {
            @content;
        }
    }
    @else if $canvas==lg {
        @media only screen and (min-width: $lg) {
            @content;
        }
    }
    @else if $canvas==xl {
        @media only screen and (min-width: $xl) {
            @content;
        }
    }
    @else if $canvas==xxl {
        @media only screen and (min-width: $xxl) {
            @content;
        }
    }
    @else if $canvas==xxxl {
        @media only screen and (min-width: $xxxl) {
            @content;
        }
    }
    @else if $canvas==xxxxl {
        @media only screen and (min-width: $xxxxl) {
            @content;
        }
    }
    @else if $canvas==xxxxxl {
        @media only screen and (min-width: $xxxxxl) {
            @content;
        }
    }
    @else {
        @media only screen and (min-width: $canvas + "px") {
            @content;
        }
    }
}

// Calc
@mixin calc($property, $expression) {
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

//Font-smoothing
@mixin font-smoothing($value: antialiased) {
    @if $value==antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}


/* Mixin */

@mixin vertical-align($position: middle) {
    position: relative;
    @if $position==middle {
        top: 50% !important;
        -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }
    @else if $position==bottom {
        top: 100% !important;
        -webkit-transform: translateY(-100%) !important;
        -ms-transform: translateY(-100%) !important;
        transform: translateY(-100%) !important;
    }
}

@mixin prefix($map, $prefixes: ("webkit" "khtml" "moz" "ms" "o")) {
    @each $prop,
    $value in $map {
        @if $prefixes {
            @each $prefix in $prefixes {
                //content
                #{"-"+ $prefix + "-"+ $prop}: $value;
            }
        }
        #{$prop}: #{$value};
    }
}

@mixin reset-text {
    font-family: $font-family-base;
    // We deliberately do NOT reset font-size or word-wrap.
    font-style: normal;
    font-weight: normal;
    line-height: $line-height-base;
    text-align: left; // Fallback for where `start` is not supported
    text-align: start; // stylelint-disable-line declaration-block-no-duplicate-properties
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    color: $blackFont;
}

@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

@mixin title_with_border() {
    border-bottom-color: $disable !important;
    border-bottom-width: 1px !important;
    margin-bottom: .5em;
    padding-bottom: 0.733em;
    margin-right: 0em;
    margin-left: 0em;
}

// Single side border-radius
@mixin border-radius($radius: $border-radius) {
    @if $enable-rounded {
        border-radius: $radius;
    }
}

@mixin border-top-radius($radius) {
    @if $enable-rounded {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
    }
}

@mixin border-right-radius($radius) {
    @if $enable-rounded {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
    }
}

@mixin border-bottom-radius($radius) {
    @if $enable-rounded {
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}

@mixin border-left-radius($radius) {
    @if $enable-rounded {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}

@mixin box-shadow($shadow...) {
    @if $enable-shadows {
        box-shadow: $shadow;
    }
}

@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}

@mixin margin($top: null, $right: null, $bottom: null, $left: null) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
}

@mixin resetWidth($size:0) {
    width: $size;
    max-width: $size;
    min-width: $size;
}

@mixin leftnavBeforeItemBckg() {
    &:before {
        border-radius: 5px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: $gradientDefault;
        background: -moz-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
        background: -webkit-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
        background: linear-gradient(to right, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
        box-shadow: 0px 3px 6px #2574A978;
        opacity: 1;
        background-size: 7px;
        background-repeat: no-repeat;
        //line-height: 2em;
        width: 7px;
        height: 100%;
    }
}

@mixin resetHeight($size:0) {
    height: $size;
    max-height: $size;
    min-height: $size;
}

@mixin bg-color($color, $important:false) {
    @if $important {
        background-color: $color !important;
    }
    @else {
        background-color: $color;
    }
}
