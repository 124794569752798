@font-face {
    font-family: 'OpenSansSemibold';
    src: url("../fonts/OpenSans-Semibold-webfont.eot");
    src: url("../fonts/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Semibold-webfont.woff") format("woff"), url("../fonts/OpenSans-Semibold-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Semibold-webfont.svg?#OpenSansSemibold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Regular-webfont.woff') format('woff'), url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'), url('../fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: 400 !important;
    font-style: normal !important;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url("../fonts/OpenSans-Bold-webfont.eot");
    src: url("../fonts/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold-webfont.woff") format("woff"), url("../fonts/OpenSans-Bold-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Bold-webfont.svg?#OpenSansBold") format("svg");
    font-weight: bold;
    font-style: normal;
}