            $app-detail-l:.833em;
            $app-detail-r:.833em;
            app-details {
                display: block;
                min-height: 100vh; // vh for viewport height
            }

            #detail {
                color: $blackLinkFont;
                hr {
                    margin-right: -$app-main-pl !important;
                    margin-left: -$app-main-pl !important;
                    border-color: $disable;
                }
                .d-flex.border-bottom,
                .d-block.border-bottom {
                    @include title_with_border();
                }
                .btn.border {
                    @include mq ('xs') {
                        margin-top: .5em;
                        padding-top: 0.267em;
                        padding-bottom: 0.267em;
                    }
                    border: 1px solid rgba($grayLine, 0.17) !important;
                    margin-right: 1px;
                    font-family: OpenSansBold;
                    color: $blackLinkFont;
                    padding: 0.533em .8em;
                    background-color: #fff;
                    border-radius: 4px;
                    &:hover {
                        text-decoration: none;
                    }
                    &+.btn {
                        margin-left: 0.667em
                    }
                }
                &>p:first-child,
                .d-flex.border-bottom,
                .d-block.border-bottom {
                    @include mq('xs') {
                        margin: 0em $app-detail-l .5em;
                        @at-root &:not(.d-flex.border-bottom):not(.d-block.border-bottom) {
                            margin-top: .2em !important;
                        }
                    }
                }
                .row>p {
                    margin: 1.417em $app-detail-l 0.7915em $app-detail-r;
                    @include mq("sm") {
                        margin-bottom: 3.5em;
                        margin-top: 2.8em;
                    }
                }
                .fas,
                .far {
                    margin-right: 0.667em;
                }
                @at-root & .previousMessage .card {
                    padding-top: 0em;
                    .reply-status {
                        margin-top: 0em;
                    }
                }
                .card {
                    &.border-top {
                        border-top-width: 2px !important;
                    }
                    flex: auto;
                    padding: 1.417em 0em 0em;
                    margin-left: $app-detail-l;
                    margin-right: $app-detail-l;
                    @include mq("sm") {
                        padding-top: 2.5em;
                        padding-bottom: 2.5em;
                    }
                    &-header,
                    &-body,
                    &-footer {
                        padding: 0;
                        margin: 0;
                        background: transparent;
                        border: 0;
                        border-radius: 0;
                    }
                    &-header {
                        strong {
                            word-break: break-all;
                        }
                    }
                    &-body {
                        margin: .833em 0;
                        div.attachmentList,
                        div.bodyContent {
                            margin-right: .83em;
                            @include mq('lg') {
                                margin-right: 0em;
                            }
                            padding-top: 0.833em;
                            padding-bottom: 0em;
                            border-top: 1px solid rgba($grayLine, 0.17);
                            &:not(.attachmentList) {
                                padding-top: 0em;
                                p {
                                    white-space: pre-wrap;
                                }
                            }
                            app-attachments {
                                ul {
                                    margin-bottom: 0em;
                                }
                                li {
                                    padding-bottom: .833em;
                                }
                            }
                        }
                        div.attachmentList+div.bodyContent {
                            border: 0px;
                        }
                    }
                    .input-light {
                        margin-bottom: 0.833em;
                        padding-top: 1.258em;
                        padding-bottom: 1.033em;
                        padding-left: 2.133em;
                        @include border-radius(11px);
                        font-size: em(14, 15);
                        input[type="checkbox"] {
                            @include border-radius(4px);
                            height: 18px;
                            width: 18px;
                        }
                        .form-check-label {
                            display: inline;
                            &::before,
                            &::after {
                                top: 0 !important;
                            }
                        }
                        ///.form-check-input:checked ~ .form-check-label::before
                        .form-check-input {
                            &:checked~.text-blue.form-check-label,
                            &:not(:disabled):active~.text-blue.form-check-label {
                                &::before {
                                    background: $blue !important;
                                    border-color: $blue !important;
                                }
                                &::after {}
                            }
                            &:focus~.text-blue.form-check-label,
                            &:focus:not(:checked)~.text-blue.form-check-label {
                                &::before {
                                    box-shadow: 0 0 0 0.2rem rgb(37 116 169 / 25%);
                                    border-color: $blue !important;
                                }
                            }
                        }
                    }
                    &-footer {
                        //background-color: $grayLighter;
                        padding: 1.333em 1.25rem;
                        @include mq('xs') {
                            margin-left: -.8333em;
                            margin-right: -.8333em;
                        }
                        @at-root {
                            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                & {
                                    padding-bottom: 0em !important;
                                }
                            }
                        }
                    }
                    .dataBlock {
                        margin-bottom: 1em;
                        h3 {
                            background-color: $grayLighter;
                            margin: 0;
                            padding: 1em;
                            a {
                                color: $grayDarker;
                            }
                            i {
                                color: $blue;
                            }
                        }
                        ul {
                            padding: 1em;
                            line-height: normal;
                            background-color: rgba($grayLighter, 0.51);
                            &>li {
                                line-height: 1.6em;
                            }
                            @include mq("lg") {
                                &:not(.multiple-value) {
                                    @include prefix((column-count: 2));
                                }
                            }
                            ul.multiple-value {
                                li {
                                    margin-right: 0;
                                    width: auto;
                                }
                                li:before {
                                    display: inline-block;
                                    content: (", ");
                                }
                                li:first-child:before {
                                    content: ("");
                                }
                              padding: 0;
                            }
                            span,
                            strong {
                                display: inline-flex;
                                width: 50%;
                                @include mq('xs') {
                                    word-break: break-word;
                                    white-space: normal !important;
                                }
                            }
                            span {
                                font-family: OpenSansRegular;
                                font-weight: normal;
                            }
                            strong {
                                font-family: OpenSansBold;
                                @include mq("lg") {
                                    text-align: right;
                                }
                                @include mq("xl") {
                                    text-align: left;
                                }
                            }
                        }
                    }
                    h3 {
                        font-family: OpenSansBold;
                        font-size: 1em;
                        margin-top: .667em;
                        margin-bottom: em(10, $font-size-base);
                        @include mq('sm') {
                            margin-top: 0em;
                        }
                        span {
                            margin-top: 1em;
                            margin-bottom: 0em;
                            display: block;
                            @extend %spacingMessage;
                            @include mq ('sm') {
                                & {
                                    padding: 0;
                                }
                            }
                        }
                        button[data-toggle=collapse] {
                            font-family: OpenSansBold;
                            font-size: 1em;
                            color: $blackFont;
                            margin: 0;
                            padding-top: 1em;
                            padding-bottom: 1em;
                            @extend %spacingMessage;
                            display: inline-block;
                            width: 100%;
                            text-align: left;
                            background-color: $grayLight;
                            border-radius: 0;
                            &:after {
                                display: inline-block;
                                font: normal normal 900 15px/1 'Font Awesome 6 Free';
                                font-size: inherit;
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                float: right;
                                margin-top: 5px;
                            }
                            &[aria-expanded=false]:after {
                                content: unicode("f054");
                            }
                            &[aria-expanded=true] {
                                margin-top: 0em;
                                margin-bottom: 0em;
                                &:after {
                                    content: unicode("f078");
                                }
                            }
                        }
                    }
                    h4 {
                        font-family: OpenSansRegular;
                        font-size: $font-size-normal;
                        margin-bottom: .292em;
                    }
                    p {
                        margin-bottom: 0em;
                        &.active {
                            color: $greenFont;
                        }
                    }
                }
                strong {
                    font-family: OpenSansBold;
                }
                .receiptDate {
                    color: $blackFont;
                    font-size: em(14, $font-size-base);
                }
                .reply-status {
                    margin-top: 0.533em;
                    margin-bottom: .333em;
                    span {
                        border-radius: 10px;
                        color: $blackLinkFont;
                        display: inline-block;
                        padding-right: 1.333em;
                        padding-left: 1.333em;
                    }
                    &-done {
                        background-color: rgba($primary, 0.04);
                    }
                    &-until {
                        background-color: rgba($red, 0.04);
                    }
                }
                .attachmentList {
                    ul {
                        margin-bottom: 0em;
                    }
                    p,
                    li {
                        font-size: 1em;
                        a {
                            font-size: $font-size-normal;
                        }
                    }
                }
                #bodyContent {
                    p {
                        white-space: pre-line;
                    }
                }
                &:not(#detail) {
                    %spacingMessage {
                        padding-left: .5em;
                        padding-right: .5em;
                    }
                    .card {
                        @include mq ('sm') {
                            & {
                                padding: 1.417em 0em 0.7085em 0em;
                                margin-right: $app-detail-r;
                                margin-left: $app-detail-l;
                            }
                        }
                        margin-right: 0em;
                        margin-left: 0;
                        padding: 0;
                        & .card-body {
                            padding-left: $app-detail-l;
                            padding-right: $app-detail-r;
                            padding-top: 2.583em;
                            &>p {
                                margin-left: 0.417em;
                                margin-right: 0.417em;
                            }
                        }
                    }
                    h3 {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
