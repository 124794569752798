        $leftnav-padding-top:1.267em;
        $leftnav-padding-bottom:1.4em;
        $leftnav-padding-left-xxxxl: 2.867em;
        app-leftnav {
            @include mq('xs') {
                width: auto !important;
            }
            padding-left: 0em !important;
            .btn.btn-link {
                @at-root body :not(li)>& {
                    @media only screen and (max-width: calc(#{$lg} - 1px)) and (min-width: #{$sm}) {
                        padding-right: 0em;
                        span {
                            @include visually-hidden;
                        }
                    }
                }
                @include mq('xxxl') {
                    margin-left: 2em !important;
                }
                @include mq('xxxxxl') {
                    margin-left: $leftnav-padding-left-xxxxl !important;
                }
                border-radius: 4px;
                border: 1px solid $grayMiddle;
                font-family: OpenSansSemiBold;
                padding-right: 1.6em;
                padding-left: 0.8em;
                i.fas {
                    margin-right: 0.6em;
                }
                line-height: 23px;
                margin-bottom: 2.267em;
                &:hover {
                    text-decoration: none;
                }
            }
            @include mq('xxxxxl') {
                padding-left: 15px !important;
            }
            /*@media only screen and (max-width: calc(#{$xxxxl} - 1px)) {}*/
            ul.nav>.active>.nav-link {
                background-color: $whiteBroken;
                position: relative;
                &:before {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background: $gradientDefault;
                    background: -moz-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                    background: -webkit-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                    background: linear-gradient(to right, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                    box-shadow: 0px 3px 6px #2574A978;
                    opacity: 1;
                    background-size: 7px;
                    background-repeat: no-repeat;
                    //line-height: 2em;
                    width: 7px;
                    height: 100%;
                }
            }
            ul {
                &.subNav {
                    padding: 1em 10px;
                    li.nav-item {
                        &.active {
                            background-color: $grayLighter;
                        }
                        .nav-link {
                            background: transparent;
                            .badge {
                                background-color: $blue;
                                color: #fff;
                                @include border-radius(4px);
                            }
                            padding-right: 1em;
                        }
                    }
                }
                &.nav {
                    .nav-link {
                        background-color: $whiteBroken;
                    }
                }
                &.nav,
                &.subNav {
                    background-color: $whiteBroken;
                    &:first-child {
                        border-top: 0px;
                    }
                    .nav-link {
                        font-family: OpenSansSemibold;
                        color: $blackLinkFont;
                        @include mq('md') {
                            line-height: $line-height;
                            padding-right: 0;
                            padding-left: 1.867em;
                            padding-bottom: calc(#{$leftnav-padding-bottom} / 2);
                            padding-top: calc(#{$leftnav-padding-top} / 2);
                        }
                        @include mq("xxxxxl") {
                            padding-left: $leftnav-padding-left-xxxxl;
                        }
                        .fas,
                        .far {
                            color: $primary;
                            font-size: em(20, $font-size-base);
                            margin-right: 0.8em;
                        }
                        @at-root body :not(li)>& {
                            @media only screen and (max-width: calc(#{$lg} - 1px)) and (min-width: #{$sm}) {
                                span {
                                    @include visually-hidden;
                                }
                                & {
                                    padding-right: 0em;
                                    padding-left: 1em;
                                    padding-bottom: calc(#{$leftnav-padding-bottom} / 2);
                                    padding-top: calc(#{$leftnav-padding-top} / 2);
                                    i {
                                        margin-right: 0em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @include mq('xs') {
                .panelToFixed.fixed {
                    .dropdown {
                        display: inline-block !important;
                    }
                }
            }
            .dropdown {
                &:not(.dropdownPartition) {
                    a.dropdown-toggle {
                        min-width: 10.5rem;
                        h2 {
                            display: inline-block;
                            font-size: 1.067em;
                        }
                    }
                }
                &:not(.dropdownFolder) {
                    .dropdown-toggle span {
                        word-break: break-all;
                        white-space: normal;
                    }
                    &.show .dropdown-toggle {
                        background-color: #fff;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
                        font-weight: bold;
                        span {
                            padding: .25rem 0 .25rem 1.8em;
                            margin-top: 1em;
                            line-height: 2em;
                            font-size: 1em;
                            @include leftnavBeforeItemBckg();
                        }
                        &::after {
                            content: unicode("f078");
                        }
                    }
                    .dropdown-menu {
                        margin-left: 1px !important;
                        margin-top: -2px;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                        -webkit-box-shadow: 4px 4px 8px -8px #d4d3de;
                        -moz-box-shadow: 4px 4px 8px -8px #d4d3de;
                        box-shadow: 4px 4px 8px -8px #d4d3de;
                        padding: .35em 0em;
                        font-size: 1em;
                        li {
                            &.dropdown-item {
                                width: auto;
                            }
                            margin-left: .833em;
                            &:active {
                                background-color: #e9ecef;
                            }
                            a:hover {
                                background-color: transparent;
                            }
                            &:hover {
                                @include leftnavBeforeItemBckg();
                            }
                        }
                    }
                }
                & {
                    a.dropdown-toggle,
                    button.dropdown-toggle {
                        &:after {
                            font-size: 0.938em;
                            border: 0px;
                            content: unicode("f078");
                            font-family: 'Font Awesome 6 Free';
                            font-weight: 800;
                            vertical-align: baseline;
                            margin-left: .5em;
                            color: $primary;
                            float: right;
                        }
                    }
                    &-menu {
                        padding: 0em 0em 0em 0.833em;
                        box-shadow: 4px 4px 16px 0px rgba(212, 211, 222, 1);
                        margin-left: -1rem;
                        margin-top: -1px;
                        /*  width: 100%;*/
                        border: 0px;
                        .nav-link {
                            font-size: 0.938em;
                            color: $blackLinkFont;
                            .fas {
                                display: none;
                            }
                        }
                    }
                }
            }
        }