$font-size-base: 15;
$line-height :em(30, $font-size-base);
$font-family-base: OpenSansRegular,
	Arial,
	Verdana,
	"Helvetica Neue",
	Helvetica,
	sans-serif;
$font-size-normal: em(14, $font-size-base);
$grayLight: #cecece;
$blackFont: #68717F;
$blackLinkFont: #24272c;
$body-color: $blackLinkFont;
$disable: #707070;
$placeholderColor: $disable;
$blackDarker: #30353c;
$grayDarker :#535353;
//use for table row even
$whiteBroken: #f8f9fa;
//use for table odd even
$grayLighter: #f1f2f3;
$grayMiddle: #CCD3DC;
//lang Background link Activ
$cyanDark: #48c0c1;
$cyanLight: #c7e7f3;
$grayFont: #898d93;
$blueFont: #19a4de;
$shadow: rgba(212, 211, 222, 1);
$blueGray: #e4e3e8;
$blueFocus: #396998;
$greenFont: #018943;
$greenFocus: #036231;
$grayLine: #707070;
$red: #dc0707;
$yellow: #fdcb1f; //253 ,203 , 31
$bg-yellow: rgba($yellow, .1);
$orange: #ff6f00;
$orangeDark: #D14424;
$blue: #2574a9;
$blueLine: #195876;
//table
$tableBlue: #363c46;
$btnPrimary: $greenFont;
$primary: $greenFont;
$secondary: rgba($primary, 0.05);
$error: $red;
$warning: $yellow;
$info: $grayLight;
$danger: $red;
$dangerCom: #ebe3e3;
$icon-browser-colors: (
	"primary": $body-color,
	"secondary": $greenFont
);
//gradient
$gradientDefault: $greenFont;
$gradientStart: $gradientDefault;
$gradientIntermediate: #2b726d;
$gradientStop: $blue;
// Popovers
// Options
//
//mediaqueries
$xs: 575px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1700px;
$xxxxl: 1920px;
$xxxxxl: 2113px;
$max-size-screen: $xxxxl;
//override
$font-size-base-forms: 1rem !default; // Assumes the browser default, typically `16px`
$line-height-base-forms: 1.52 !default;
$input-btn-line-height: $line-height-base-forms !default;
$input-btn-padding-y: .375rem !default;
$input-btn-padding-x: .75rem !default;
$input-padding-y: $input-btn-padding-y !default;
$input-line-height: $input-btn-line-height !default;
$input-height-inner: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2}) !default;
$input-height-inner-btn: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + 1px) !default;
$alert-bg-level: 0 !default;
$alert-border-level: 0 !default;
$alert-color-level: 0 !default;
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px,
	xxxl: 1700px,
	xxxxl:1920px,
	xxxxxl: 2113px
);
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1400px,
	xxxl:1610px,
	xxxxl: 1870px,
	xxxxxl: 2073px
);
$custom-file-text: (
	en: "Browse",
	fr: "Parcourir",
	nl:"Bestand kiezen",
	de:"Datei auswählen"
);
$custom-select-indicator-color: #343a40 !default;
;
$custom-select-indicator: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
		"#",
		"%23") !default;

/*$custom-select-background: $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)*/

$app-main-pl: 1.667em;
$app-main-ps-lg: 4.667em;