@charset "UTF-8";

/* You can add global styles to this file, and also import other style files */

@import './fonts';
@import './functions';
@import './fontAwesomeImport';
@import '../../../node_modules/bootstrap/scss/functions';
@import './variables';
@import './calendar';
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import 'forms_old_bootrsap';
@import './mixins';
@import './fontAwesomeCustom';
@import './custom-forms';

@import './body';
@import './main';
@import './message';
@import './leftNav';
@import './attachments';
@import './commonPart';
@import './paginationPart';
@import './detail_and_message';
@import './publicationService';
@import './management';
@import './optin';
@import './popup';
@import './popover';
@import './component';
@import './loading';
@import './error';
@import './pacman';

/**/