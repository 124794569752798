//#body.scss
body {
    font-size: $font-size-base + px;
    font-family: $font-family-base;
    color: $blackFont;
    background-color: #fff;
    line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: OpenSansSemibold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    a {
        font-size: 1em;
    }
}

h1 {
    font-size: 32px;

    span {
        font-size: 1em;
        display: block;
        color: #fff;
        font-family: $font-family-base;
        padding-top: .5em;
    }
}

h2 {
    font-size: 24px;
    color: $blackLinkFont;

    & .badge {
        background: $blueFont;
        font-size: em(22, 36)
    }
}

h3 {
    font-size: 20px;
    color: $blackLinkFont;
}

h4 {
    font-size: 16px;
    color: $blackDarker;
}

p,
label,
.form-control-plaintext,
legend span,
ol li {
    font-size: $font-size-normal;
    color: $blackLinkFont;
}





.text-underline {
    text-decoration: underline;
}

.text-blue {
    color: $blue;
}

.bg-light-blue {
    background-color: rgba($blue, 0.05);
}

.bg-light-primary {
    background-color: rgba($primary, 0.05);
}

.bg-gray {
    @include bg-color(#{$whiteBroken}, true);
}


/* contrast */

form {
    &.ng-invalid .invalid-feedback {
        color: $red;

        span {
            font-size: em(11, 12);
        }

        line-height: em(24, $font-size-base);
    }

    &.ng-invalid .invalid-field {
        &>span:first-child {
            font-family: 'OpenSansBold';
            color: $red;
        }

        .invalid-feedback {
            display: inline-flex;
            width: auto;
            white-space: break-spaces;
        }
    }

    .text-muted {
        line-height: em(24, $font-size-base);
    }
}

input.form-control,
textarea.form-control,
.custom-file-label {
    &:not([type="search"]) {
        border-radius: 4px;
    }
}

button,
a,
input {
    &.btn.btn-primary {
        border-radius: 4px;
    }
}

.required {
    color: $red !important;
}

input[type="checkbox"] {
    height: 20px;
    width: 20px;
    vertical-align: middle;
}

input[type="search"] {
    font-size: em(15, $font-size-base);
}

input[type="search" i]::-webkit-search-cancel-button {
    display: none !important;
}

.form-control {
    color: $blackFont;
}

input,
textarea,
.custom-file-label {
    &:not(input):not(textarea):after {
        background-color: $primary;
        color: #fff;
    }

    // border-color: #7f7c7c !important;
    border-color: $grayLine !important;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholderColor !important;
        opacity: 1 !important;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $placeholderColor !important;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $placeholderColor !important;
    }
}

.input-group .form-control {
    &:focus {
        border-color: darken($grayLine, 5%) !important;
    }
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: inherit;
}

.row.equal-height-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.row.equal-height-row>[class*='col-'] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}



@include mq('xs') {
    .panelToFixed.fixed {
        position: fixed;
        width: 100%;
        z-index: 1;

        @at-root app-communication & {
            z-index: 3;
        }

        &.firstFixedHeader {
            top: 0;
            z-index: 2;

            &.dropdownOpen {
                z-index: 5;
            }
        }

        @at-root main#{&} {
            margin-top: 0;
        }
    }
}

a {
    text-decoration: none;

    // color: $primary;
    &:focus,
    &:hover,
    &:active {
        color: $greenFocus;
    }

    &:hover {
        text-decoration: none;
    }
}

li.break,
.break {
    flex-basis: 100%;
    height: 0;

    &::after {
        content: ' ';
    }
}

.btn-primary,
.custom-file-label,
.custom-file-input {
    &:not(.custom-file-label):not(.custom-file-input) {
        font-family: OpenSansBold;
        font-size: 0.933em
    }

    &:focus,
    &:active {
        background: $greenFocus;
        color: #fff;
    }

    &.disabled,
    &:disabled,
    &:disabled~.custom-file-label:after {
        border-color: transparent !important;
        background-color: $disable !important;
        opacity: 1;
    }

    &:hover:not(.disabled):not(:disabled),
    &:focus:not(.disabled):not(:disabled) {
        background-color: darken($greenFont, 10%) !important;
    }
}

.btn-secondary {
    color: $primary;
    font-family: 'OpenSansSemiBold';

    &.disabled {
        color: white;
    }

    &:hover,
    &:focus {
        background-color: rgba($primary, 0.15);
        color: $primary;
    }
}

a.btn-link,
button.btn-link {

    &:hover,
    &:focus {
        background-color: $grayLighter !important;
    }
}

.form-group {
    margin-bottom: 1em;
}

.flex-basis- {
    &1 {
        flex-basis: 100%;
    }
}

.bg-yellow {
    background-color: $bg-yellow;
}

.bg-transparent {

    &:hover,
    &:focus {
        background-color: transparent !important;
    }
}

#dpLocalEnabled {
    background: url("../images/dp_enabled_bg.png") repeat 0 0;
}

#dpLocalEnnabledBanner {
    background-color: $red;

    h2 {
        color: $white;
        font-size: 16px;

        span {
            font-size: inherit;
        }

        button {
            font-size: em($font-size-base, 16);
            text-decoration: underline;
            margin: 0;
            padding: 0;

            &:before {
                display: inline-block;
                font: normal normal 900 15px/1 'Font Awesome 6 Free';
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin-top: 5px;
            }

            &[aria-expanded=false]:before {
                content: unicode("f054");
            }

            &[aria-expanded=true]:before {
                content: unicode("f078");
            }
        }
    }

    .card-body.show {
        color: $white;
        white-space: pre;
    }
}

.desktop-only {
    @include mq("xs") {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
}

.env-acceptation,
#dpLocalEnabled .env-acceptation {
    app-main {
        background-image: url("../images/accept-content-bg-gray.png") !important;
    }
}

@include mq("xs") {
    .d-xs-none {
        display: none !important;
    }

    .d-xs-inline {
        display: inline !important;
    }

    .d-xs-inline-block {
        display: inline-block !important;
    }

    .d-xs-block {
        display: block !important;
    }

    .d-xs-table {
        display: table !important;
    }

    .d-xs-table-row {
        display: table-row !important;
    }

    .d-xs-table-cell {
        display: table-cell !important;
    }

    .d-xs-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xs-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}
